<template>
  <div class="snackbar-box safe-area-left safe-area-right" :class="getBoxClass()">
    <div class="snackbar-wrapper">
      <!-- 汎用スナックバー -->
      <div v-if="!props.layout || props.layout === Layout.Normal">
        <div class="snackbar" @click="onClick">
          <div :class="getClass()">
            <div class="flex flex snackbar-inner">
              <div v-if="(props.leftImageAsset && props.leftImagePartition !== undefined && props.leftImageCd)" class="snackbar-image">
                <ResourceImg
                  v-if="props.leftImageAsset"
                  :asset="props.leftImageAsset"
                  :partition="props.leftImagePartition"
                  :cd="props.leftImageCd"
                />
              </div>
              <div v-else-if="props.leftImagePath" class="snackbar-image">
                <img :src="props.leftImagePath">
              </div>
              <div class="grow">
                <div class="text-md">{{ props.title }}</div>
                <div class="text-sm">{{ props.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- パネルミッション用スナックバー -->
      <div v-else-if="props.layout === Layout.Panelmission">
        <div :class="getClass()" class="panelmission-snackbar relative" @click="onClick">
          <img src="~/assets/images/panelmission/snackbar_bg.png" alt="">
          <div class="panelmission-snackbar-inner absolute">
            <div class="panelmission-snackbar-title flex items-center absolute">{{ props.title }}</div>
            <div class="panelmission-snackbar-text flex items-center absolute">{{ props.text }}</div>
            <div class="panelmission-snackbar-message flex justify-center items-center absolute">{{ props.message }}</div>
          </div>
        </div>
        <div class="snackbar-close-button" @click="onClickRemoveSnackbar" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Layout } from '~/composables/useSnackbar';

const props = withDefaults(defineProps<{
  leftImageAsset?: string,
  leftImagePartition?: number,
  leftImageCd?: string,
  leftImagePath?: string,
  title: string,
  text: string,
  id: string,
  long?: boolean,
  timeout?: number,
  layout?: string,
  message?: string,
  onClickParam?: string,
  onClick?: void,
}>(), {
  leftImageAsset: undefined,
  leftImagePartition: undefined,
  leftImageCd: undefined,
  leftImagePath: undefined,
  timeout: 3000,
  long: false,
  layout: Layout.Normal,
  message: undefined,
  onClickParam: undefined,
  onClick: undefined,
});

interface Emits {
  (e: 'onClick', param?: string): void;
}

const emit = defineEmits<Emits>();
const getBoxClass = () => {
  return {
    'safe-area-top': !props.long,
    long: props.long,
  };
};

const getClass = () => {
  return {
    'safe-area-top': props.long,
  };
};

const onClick = (): void => {
  useSnackbar().remove(props.id);
  emit('onClick', props.onClickParam);
};
const onClickRemoveSnackbar = (): void => {
  useSnackbar().remove(props.id);
};
setTimeout((id: string) => {
  useSnackbar().remove(id);
}, props.timeout, props.id);
</script>

<style lang="scss" scoped>
@use "assets/css/base" as base;
@use "assets/css/mixin" as mixin;
@use "assets/css/variables" as variables;

.snackbar-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  &.long {
    top: -36px;

    .snackbar-inner {
      padding-top: 36px;
    }
  }
}

.snackbar-wrapper {
  margin: 0 auto;

  @include mixin.min-aspect() {
    position: absolute;
  }

  @include mixin.tablet() {
    max-width: variables.$tablet-width;
  }

  @include mixin.desktop() {
    max-width: variables.$desktop-width;
  }
}

.snackbar {
  padding: 16px 20px;
  margin-top: base.$layout-top;
  margin-left: base.$layout-left;
  margin-right: base.$layout-right;
  border-image: url("~/assets/images/common/common_popup_background.png") 60 60 fill / 20px 20px stretch;

  .snackbar-image {
    width: 86px;
    line-height: 0;
    padding-right: 10px;
  }
}

.snackbar-close-button {
  width: 25px;
  height: 25px;
  background: url("~/assets/images/common/common_btn_close_02.png") center no-repeat;
  background-size: contain;
  position: relative;
  float: right;
  bottom: 18px;
  right: 5px;
}

.panelmission-snackbar {
  margin-left: auto;
  margin-right: auto;
  max-width: 365px;
}

.panelmission-snackbar-inner {
  inset: 13% 7.5% 13% 5%;
}

.panelmission-snackbar-title {
  color: #fff;
  font-size: 0.83em;
  white-space: pre-wrap;
  line-height: 1em;
  top: 0;
  left: 0;
  width: 62%;
  height: 2.2em;
}

.panelmission-snackbar-text {
  color: #291e0d;
  font-size: 0.83em;
  line-height: 1em;
  white-space: pre-wrap;
  left: 0;
  bottom: 0;
  width: 62%;
  height: 3.4em;
}

.panelmission-snackbar-message {
  color: #fff;
  font-size: 1em;
  white-space: pre-wrap;
  text-align: center;
  top: 16%;
  right: -4px;
  width: 35.5%;
  height: 3.3em;
}
</style>
